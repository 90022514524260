import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Permission, Role } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';

export interface RolesAndPermissionsState {
    roles: Role[];
    selectedRole: Role | null;
    permissions: Permission[];
    addRoleDialog: boolean;
    addPermissionDialog: boolean;
}

@Injectable()
export class RolesAndPermissionsStore extends ComponentStore<RolesAndPermissionsState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
    ) {
        super({
            roles: [],
            selectedRole: null,
            permissions: [],
            addRoleDialog: false,
            addPermissionDialog: false,
        });
    }

    readonly getRoles = this.select((state) => state.roles.sort((a, b) => a.level - b.level));
    readonly getPermissions = this.select((state) => state.permissions);
    readonly getAddRoleDialog = this.select((state) => state.addRoleDialog);
    readonly getSelectedRole = this.select((state) => state.selectedRole);
    readonly getAddPermissionDialog = this.select((state) => state.addPermissionDialog);

    readonly updateRole = this.updater((state, role: Role) => {
        const roles = state.roles;
        const roleIndex = state.roles.findIndex((r) => r.id === role.id);
        roles.splice(roleIndex, 1, role);

        return { ...state, roles };
    });

    readonly getRolesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Role[]>('/api/roles-and-permissions/roles').pipe(
                    tap((roles) => this.patchState({ roles })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getPermissionsEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Permission[]>('/api/roles-and-permissions/permissions').pipe(
                    tap((permissions) => this.patchState({ permissions })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly saveRoleEffect = this.effect((role$: Observable<Partial<Role>>) =>
        role$.pipe(
            switchMap((role) => {
                if (role.id) {
                    return this.http.patch<Role>(`/api/roles-and-permissions/roles/${role.id}`, role).pipe(
                        tap((role) => {
                            this.updateRole(role);
                            this.patchState({ addRoleDialog: false, selectedRole: null });
                            this.toastr.success('Rolul a fost modificat cu succes!');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                } else {
                    return this.http.post<Role>('/api/roles-and-permissions/roles', role).pipe(
                        tap((role) => {
                            const roles = this.get().roles;
                            this.patchState({ addRoleDialog: false, selectedRole: null, roles: [...roles, role] });
                            this.toastr.success('Rolul a fost adaugat cu succes!');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
            }),
        ),
    );

    readonly deleteRoleEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/roles-and-permissions/roles/${id}`).pipe(
                    tap((_) => {
                        const roles = this.get().roles;
                        this.patchState({ addRoleDialog: false, roles: roles.filter((r) => r.id !== id) });
                        this.toastr.success('Rolul a fost sters cu succes!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly savePermissionEffect = this.effect((permission$: Observable<Partial<Permission>>) =>
        permission$.pipe(
            switchMap((permission) =>
                this.http.post<Permission>('/api/roles-and-permissions/permissions', permission).pipe(
                    tap((permission) => {
                        const permissions = this.get().permissions;
                        this.patchState({ addPermissionDialog: false, permissions: [...permissions, permission] });
                        this.toastr.success('Rolul a fost adaugat cu succes!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly saveRolePermissionsEffect = this.effect((roleAndPermissions$: Observable<{ roleId: string; permissions: string[] }>) =>
        roleAndPermissions$.pipe(
            switchMap((roleAndPermissions) =>
                this.http.patch<Role>(`/api/roles-and-permissions/roles/${roleAndPermissions.roleId}`, { permissions: roleAndPermissions.permissions }).pipe(
                    tap((role) => {
                        this.updateRole(role);
                        this.toastr.success('Rolul a fost modificat cu succes!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
}
